import OneLineLabel from "../Label/OneLineLabel";
import { Element } from "react-scroll";
import GoogleMap from "../Map/GoogleMap";

export default function ContactUs() {
  const ContactInfo = ({ text, children }) => {
    return (
      <div className="mb-4">
        <div className="uppercase text-gray-600 text-sm pb-4">{text}</div>

        <span className="text-md font-bold">{children}</span>
      </div>
    );
  };
  return (
    <Element name="Contact Us">
      <div
        className="px-4 pt-20 pb-20 lg:pt-32 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 "
        id="contact_us"
      >
        <div>
          <OneLineLabel text="Have you question ?" />
          <h1 className="text-3xl my-4">
            We Are&nbsp;
            <span className=" text-brand-yellow">In Touch</span>
          </h1>
          <ContactInfo
            text="Address"
            children={
              <>
                Tower B-PH1, Shwe Zabu River View Condo,
                <br />
                Kyi Myin Daing River View Street,
                <br /> Alone Township, Yangon
              </>
            }
          />
          <ContactInfo
            text="Phone"
            children={
              <>
                <a href="tel:09769 9269 71" className="underline">
                  +959769926971
                </a>
              </>
            }
          />
          <ContactInfo
            text="email"
            children={
              <a href="mailto:info@ovmyanmar.com" className="underline">
                info@ovmyanmar.com
              </a>
            }
          />
        </div>
        <div className="mt-5 relative shadow-map hidden md:block">
          <GoogleMap />
        </div>
      </div>
    </Element>
  );
}
